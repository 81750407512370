<template>
  <SfList class="social-links">
    <SfListItem
      class="social-links__item"
      v-for="social in socials"
      v-show="social.link"
      :key="social.title"
    >
      <SfLink
        v-if="social.link"
        class="social-links__link"
        :title="social.title"
        :link="social.link"
        target="_blank"
      >
        <SfIcon
          class="social-links__icon"
          :icon="social.icon"
          :label="social.title"
          style="--icon-size: 28px"
        />
      </SfLink>
    </SfListItem>
  </SfList>
</template>

<script>
import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api';

import {
  SfList,
  SfLink,
  SfIcon
} from '~/components';

import { useWebsiteCode } from '~/composables';

export default defineComponent({
  name: 'SocialLinks',
  components: {
    SfList,
    SfLink,
    SfIcon
  },
  setup() {
    const {
      isInt,
      isAu,
      isMe
    } = useWebsiteCode();

    const websiteSpecifiedLinks = {
      facebook:
        isInt.value ? 'https://www.facebook.com/togasofficial/' :
        isAu.value ? 'https://www.facebook.com/togasofficial.at' :
        isMe.value ? 'https://www.facebook.com/profile.php?id=100087051287020&mibextid=ZbWKwL' : null,
      instagram:
        isInt.value ? 'https://www.instagram.com/togasofficial/' :
        isAu.value ? 'https://www.instagram.com/togasofficial.at' :
        isMe.value ? 'https://www.instagram.com/togasofficial.mideast?igsh=MXhwaTVya3AycjY0dg==' : null,
      youtube: 'https://www.youtube.com/channel/UCSwAK9wBYRBYSjFWsDgo8nw',
      telegram: isAu.value ? 'https://t.me/togasaustria' : null,
      whatsapp: isAu.value ? 'https://wa.me/message/KZIAQGYDERWRJ1' : null,
      pinterest: 'https://pin.it/ZXiUaBj',
    };

    const socials = computed(() => ([
      {
        title: 'Facebook',
        icon: 'social_facebook',
        link: websiteSpecifiedLinks?.facebook
      },
      {
        title: 'Instagram',
        icon: 'social_instagram',
        link: websiteSpecifiedLinks?.instagram
      },
      {
        title: 'Youtube',
        icon: 'social_youtube',
        link: websiteSpecifiedLinks?.youtube
      },
      {
        title: 'Pinterest',
        icon: 'social_pinterest',
        link: websiteSpecifiedLinks?.pinterest

      },
      {
        title: 'Telegram',
        icon: 'social_telegram',
        link: websiteSpecifiedLinks?.telegram
      },
      {
        title: 'Whatsapp',
        icon: 'social_whatsapp',
        link: websiteSpecifiedLinks?.whatsapp
      }
    ]));

    return {
      socials
    };
  }
});
</script>
<style lang="scss" scoped>
.social-links {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include for-mobile {
    margin-bottom: var(--spacer-base);
  }

  @include for-desktop {
    width: 270px;
    padding-bottom: 21px;
    margin: -12px 0 0 auto;
  }

  &__link {
    --icon-color: #C4C4C4;
  }
}
</style>
